<script setup lang="ts">
import type { OfferPreset } from "@/types";

const props = withDefaults(
	defineProps<{
		offer: OfferPreset;
		withDepositStreak?: boolean;
		withButton?: boolean;
		eventDecor?: boolean;
	}>(),
	{ withButton: false }
);

const { t } = useT();
const isGuest = useIsGuest();
const { open } = useAppModals();
const { isEventTheme } = useEventsCollector();
const openCashDeposit = (offer: OfferPreset) => {
	if (isGuest.value) {
		open("LazyOModalSignup");
		return;
	}
	const url = offer?.promoOfferPreset
		? `/cash/deposit-by-money/${offer.promoOfferPreset.id}/promoOffer/`
		: `/cash/deposit-by-money/${offer.preset.id}/preset/`;

	window?.$cash?.$router?.push?.(url);
};

const isPresetPackage = computed(() => !!props.offer?.promoOfferPreset);
const isBestDeal = computed(() => props.offer?.promoOfferPreset?.bestDeal);
const isMostPopular = computed(() => props.offer?.promoOfferPreset?.mostPopular);
const badge = computed(() => parseJSON(props.offer?.promoOfferPreset?.badgeLabel || "{}"));
const coins = computed(() =>
	isPresetPackage.value ? props.offer?.promoOfferPreset?.coins : props.offer?.preset?.coins
);
const entries = computed(() =>
	isPresetPackage.value ? props.offer?.promoOfferPreset?.entries : props.offer?.preset?.entries
);
const usualPrice = computed(() => props.offer?.promoOfferPreset?.usualPrice);
const isEvent = computed(() => isEventTheme.value && props.eventDecor && (isBestDeal.value || isMostPopular.value));
</script>

<template>
	<div class="wrapper" :class="{ 'wrapper--with-gradient': !isPresetPackage }">
		<div
			class="promotion"
			:class="{
				'promotion--with-preset': isPresetPackage,
				'promotion--best-deal': isBestDeal,
				'promotion--most-popular': isMostPopular,
				'promotion--outside-button': withButton
			}"
			@click="openCashDeposit(offer)"
		>
			<div class="promotion__data">
				<ABadge v-if="isBestDeal || isMostPopular" background="var(--goeteborg)" variant="info" autosize>
					<AText variant="ternopil" class="text-carabanchel" :modifiers="['bold', 'uppercase']">
						{{ isBestDeal ? t("Best deal") : t("Most popular") }}
						<template v-if="badge?.percent && badge?.text"> - {{ badge?.percent }} {{ badge?.text }}</template>
					</AText>
				</ABadge>

				<AText v-if="coins" variant="tulsa" :modifiers="['bold', 'uppercase', 'bold']" class="count text-tlalnepantla">
					<NuxtImg src="/nuxt-img/prizes/funcoins.png" width="22" height="22" alt="Funcoins prize" format="avif" />
					{{ numberFormat(coins) }}
				</AText>

				<AText
					v-if="entries"
					variant="tulsa"
					:modifiers="['bold', 'uppercase', 'bold']"
					class="count text-tlalnepantla"
				>
					<NuxtImg src="/nuxt-img/prizes/citycoins.png" width="22" height="22" alt="Citycoins prize" format="avif" />
					{{ numberFormat(entries) }}
					<AText variant="ternopil" class="count-after" :modifiers="['bold']">
						{{ t("Free City Coins") }}
					</AText>
				</AText>
			</div>
			<div v-if="!withButton" class="promotion__action">
				<AText v-if="usualPrice" variant="topeka" :modifiers="['semibold']" class="foot">
					<i18n-t keypath="was {key}">
						<template #key>
							$<s>{{ usualPrice }}</s>
						</template>
					</i18n-t>
				</AText>
				<AButton variant="primary" size="lg" class="promo">
					<AText :modifiers="['semibold']" class="text-tlalnepantla">${{ offer.money }}</AText>
					<NuxtImg
						v-if="withDepositStreak"
						src="/nuxt-img/deposit-streak/box-prize.webp"
						class="deposit-prize-box"
						width="45"
						height="48"
						format="avif"
						alt="deposit-prize-box"
						loading="lazy"
					/>
				</AButton>
			</div>
		</div>
		<NuxtImg
			v-if="isEvent"
			src="/nuxt-img/event-decor/black-friday-sale.svg"
			class="black-friday-sale"
			width="440"
			height="440"
			alt="black friday sale icon"
			loading="lazy"
		/>
		<AButton v-if="withButton" variant="primary" size="lg" class="buy" @click="openCashDeposit(offer)">
			<AText variant="toledo" :modifiers="['semibold']" class="text-tlalnepantla">
				{{ t("Buy Now") }} ${{ offer.money }}
			</AText>
		</AButton>
	</div>
</template>

<style lang="scss" scoped>
.wrapper {
	border-radius: 12px;
	position: relative;
	&--with-gradient {
		padding: 1px;
		background: var(--garoua);
	}
	.black-friday-sale {
		position: absolute;
		right: 0;
		top: 0;
		width: 113px;
		height: 60px;
	}
}
.promotion {
	position: relative;
	width: 100%;
	min-height: 92px;
	max-height: 92px;
	border-radius: inherit;
	background: var(--goiania);
	padding: gutter(2);
	display: flex;
	justify-content: space-between;
	transition: 0.2s;

	.badge {
		width: max-content;
		box-shadow: none;
	}

	&:hover {
		cursor: pointer;
		box-shadow: 0 0 6px 0 var(--cangzhou);
	}

	&__data,
	&__action {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
	}

	&__action {
		justify-content: center;
		align-items: center;
		gap: gutter(0.5);

		button {
			width: 129px;
		}
	}

	&--with-preset {
		padding: gutter(5) gutter(2) gutter(2);
		min-height: 118px;
		max-height: 118px;
	}

	&--best-deal {
		background: var(--guaruja);
	}
	&--most-popular {
		background: var(--guliston);
	}
	&--outside-button {
		justify-content: center;

		&:deep(.badge) {
			left: 0;
			right: 0;
			margin: 0 auto;
		}
	}
}
.badge {
	position: absolute;
	top: 16px;
	left: 16px;
	padding: 0 gutter(1);
}
.deposit-prize-box {
	width: 22px;
	height: 24px;
	object-fit: cover;
	margin-left: 8px;
}
.count {
	display: flex;
	align-items: center;
	gap: gutter(0.5);
}
.count-after {
	width: 60px;
}
.buy {
	width: 100%;
	margin-top: gutter(2);
}
</style>
